import React from 'react'
import styled from 'styled-components'
import SemiTitle from '@/components/UI/SemiTitle'

const AgendaTitle = styled(SemiTitle)<{ animated?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  color: ${({ theme }) => theme.colors.blue};
  padding-top: 40px;
`

const List = styled.ul`
  list-style: square;
  padding-left: 25px;
  max-width: 600px;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
    max-width: 750px;
  }
`

const ListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  color: ${({ theme }) => theme.colors.blue};
  padding-top: 25px;
  line-height: 28px;

  p {
    font-size: ${({ theme }) => theme.fontSize.bigText};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
  }

  div {
    font-size: ${({ theme }) => theme.fontSize.bigText};
    color: ${({ theme }) => theme.colors.text};
  }
`

const SubList = styled.ul`
  list-style: square;
`

const SubListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSize.bigText};
  color: ${({ theme }) => theme.colors.cyan};
  padding-top: 15px;

  span {
    font-size: ${({ theme }) => theme.fontSize.bigText};
    color: ${({ theme }) => theme.colors.text};
  }
`

const Author = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const EventAgenda: React.FC = () => {
  return (
    <>
      <AgendaTitle>Agenda</AgendaTitle>
      <List>
        <ListItem>
          <p>Coffee and Light Breakfast.</p>
        </ListItem>
        <ListItem>
          <p>Opening – Fortress Integrative Cyber Defense vision.</p>
          <div>
            By <Author>Menachem Tauman</Author>, Fortress Co-founder and
            CEO.
          </div>
        </ListItem>
        <ListItem>
          <p>
            Fortress Platform – Democratize Cybersecurity as a service.
          </p>
          <div>
            The MSP, MSSP and IT Vendors cybersecurity administration &
            management problem.
          </div>
          <div>
            By <Author>Gregory Nazarovsky</Author>, Fortress Co-founder and
            CSO.
          </div>
        </ListItem>
        <ListItem>
          <p>How Fortress can Boost Your Business Growth.</p>
          <SubList>
            <SubListItem>
              <span>
                Offer your customers enterprise-grade solutions at an
                affordable cost.
              </span>
            </SubListItem>
            <SubListItem>
              <span>
                Expand your service offerings through Fortress’ resources
                and team of experts.
              </span>
            </SubListItem>
            <SubListItem>
              <span>
                Upgrade your cybersecurity services and shift to a
                proactive mode.
              </span>
            </SubListItem>
            <SubListItem>
              <span>
                Manage all your business administration in one place.
              </span>
            </SubListItem>
          </SubList>
          <div>
            by <Author>Gregori Nazarovsky</Author>, Fortress Co-founder and
            CSO.
          </div>
        </ListItem>
        <ListItem>
          <p>Live Demo</p>
        </ListItem>
        <ListItem>
          <p>Q&amp;A &amp; How to get started.</p>
        </ListItem>
      </List>
    </>
  )
}

export default EventAgenda
