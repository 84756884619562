import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.semiTitle};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    font-size: ${({ theme }) => theme.fontSize.title};
  }
`

const Title: React.FC = (props) => {
  const { children } = props

  return <StyledTitle {...props}>{children}</StyledTitle>
}

export default Title
