import React, {
  createContext,
  ReactChild,
  ReactChildren,
  useState,
} from 'react'
import { ThemeProvider } from 'styled-components'
import theme from '../utils/theme'
import GlobalStyle from '../utils/globalStyles'
import '../static/fonts/fonts.css'
import ModalContactForm from '@/components/ContactForm/ModalContactForm'
import Seo from '@/components/SEO/SEO'
import { NavigationEvent } from './Navigation/NavigationEvent'

type Props = {
  children: ReactChild | ReactChildren | JSX.Element[]
}

export const ModalContext = createContext({})

const LayoutEvent: React.FC<Props> = ({ children }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ModalContext.Provider value={setIsModalOpen}>
        <Seo />
        <main
          style={{
            height: '100%',
          }}
        >
          <NavigationEvent />
          {children}
        </main>
        <ModalContactForm toggle={toggle} isOpen={isModalOpen} />
      </ModalContext.Provider>
    </ThemeProvider>
  )
}

export default LayoutEvent
