import React from 'react'
import styled, { css } from 'styled-components'
import Title from '../UI/Title'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'

import theme from '@/utils/theme'
import { StaticImage } from 'gatsby-plugin-image'
import SemiTitle from '../UI/SemiTitle'
import ContactFormEvent from '../ContactForm/ContactFormEvent'
import Clouds from '../../assets/defendYourDataBg.png'
import EventAgenda from '@/components/Event/EventAgenda'

const Wrapper = styled.div`
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 901px) {
    display: block;
  }
`

const WrapperClouds = styled.div<{ backgroundImage?: string }>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
`

const ContentWrapper = styled.div`
  padding: 0 20px;
  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    padding: 10px 8%;
  }
`

const Titles = styled.div`
  max-width: 500px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    margin-bottom: 40px;
    margin-right: 50px;
  }
`

const StyledSemiTitle = styled(SemiTitle)<{ animated?: boolean }>`
  max-width: 650px;
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  color: ${({ theme }) => theme.colors.blue};
  padding-top: 16px;

  ${({ animated }) =>
    animated &&
    css`
      opacity: 0;
      animation-delay: 0.3s;
      animation-name: fadeInUp;
      animation-fill-mode: both;
      animation-duration: 2s;

      @keyframes fadeInUp {
        from {
          transform: translateY(20px);
        }

        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `}
`

const StyledTitle = styled(Title)<{ animated?: boolean }>`
  text-align: left;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
    font-size: 44px;
  }

  ${({ animated }) =>
    animated &&
    css`
      animation-duration: 2s;
      animation-name: fadeInDown;
      @keyframes fadeInDown {
        0% {
          opacity: 0;
          transform: translateY(-20px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `}
`

const Text = styled.div`
  animation: fadein 2s;
  animation-fill-mode: both;
  animation-delay: 0.4s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  line-height: 26px;
  margin: 10px 0;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
    max-width: 500px;
  }

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    font-size: ${({ theme }) => theme.fontSize.text};
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    font-size: ${({ theme }) => theme.fontSize.bigText};
  }
`

const Register = styled.div`
  display: flex;
  z-index: 100;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    position: fixed;
    right: 10%;
    top: 145px;
  }
`

const SummariesTitle = styled(SemiTitle)<{ animated?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  color: ${({ theme }) => theme.colors.blue};
  padding-top: 16px;
  max-width: 700px;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
    max-width: 750px;
  }
`

const Summaries = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 33px 0;
  max-width: 750px;
  @media (min-width: ${({ theme }) => theme.rwd.mobile.m}) {
    flex-direction: row;
  }
`

const Summary = styled.div`
  padding-top: 20px;
  line-height: 28px;
  flex: 1;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.bigText};
`

const LeadersBoxLeft = styled.div`
  position: absolute;
  left: -25px;
  bottom: -25px;
`

const LeadersBoxRight = styled.div`
  position: absolute;
  right: -40px;
  top: -30px;
`

const LeadersBox = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 12px;
  max-width: 261px;
  padding: 22px 40px 13px 30px;
  margin: 65px 0 65px 26px;

  font-size: ${({ theme }) => theme.fontSize.bigText};
  line-height: 28px;

  color: white;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    display: flex;
    flex-direction: row;
    max-width: 670px;
  }
`

const ClosingMarks = styled.div`
  font-size: ${({ theme }) => theme.fontSize.bigText};
  line-height: 28px;
  font-weight: bold;
`

const Email = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: bold;
`

export const EventHead: React.FC = () => {
  const { backgroundImageBig } = useStaticQuery(graphql`
    {
      backgroundImageBig: file(relativePath: { eq: "landingBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImageWrapper
      styles={{ backgroundPositionY: '-70px', zIndex: 10 }}
      image={backgroundImageBig.childImageSharp.fluid}
      backgroundColor={theme.colors.lightGray}
    >
      <Wrapper>
        <ContentWrapper>
          <Titles>
            <StyledTitle animated>
              Calling all MSP, MSSP and IT Vendors
            </StyledTitle>
            <StyledSemiTitle animated>
              Discover How Fortress Can Boost Your Business Growth
            </StyledSemiTitle>
          </Titles>
          <Text>
            If you are an MSP, MSSP or IT Vendor, Fortress enables you to
            manage all your cybersecurity services and administration in a
            single pane of glass.
          </Text>
        </ContentWrapper>
        <WrapperClouds backgroundImage={Clouds}>
          <ContentWrapper>
            <StaticImage
              width={480}
              placeholder={'blurred'}
              src={'../../assets/productTowerLanding.png'}
              alt={'product tower'}
            />
            <SummariesTitle>
              Join Us To Learn About Fortress’ New Technology
            </SummariesTitle>
            <Summaries>
              <Summary>
                <StaticImage
                  width={76}
                  placeholder={'blurred'}
                  src={'../../assets/date.png'}
                  alt={'date'}
                />
                <div>
                  June 15th
                  <br />
                  Morning session
                  <br />
                  09:30 – 12:30
                </div>
              </Summary>
              <Summary>
                <StaticImage
                  width={76}
                  placeholder={'blurred'}
                  src={'../../assets/location.png'}
                  alt={'date'}
                />
                <div>
                  Carloton Hotel
                  <br />
                  Tel-Aviv
                </div>
              </Summary>
            </Summaries>
          </ContentWrapper>
        </WrapperClouds>
        <ContentWrapper>
          <Register>
            <ContactFormEvent />
          </Register>
          <EventAgenda />
          <LeadersBox>
            <LeadersBoxLeft>
              <StaticImage
                width={38}
                placeholder={'blurred'}
                height={38}
                layout="fixed"
                src={'../../assets/leadersBoxLeft.png'}
                alt={'leaders box left'}
              />
            </LeadersBoxLeft>
            Fortress is led by QMasters founders,
            <br />
            Menachem Tauman and Gregori Nazarovsky,
            <br />
            with 30 + years combined experience in IT and Cybersecurity.
            <LeadersBoxRight>
              <StaticImage
                width={49}
                placeholder={'blurred'}
                height={42}
                layout="fixed"
                src={'../../assets/leadersBoxRight.png'}
                alt={'leaders box right'}
              />
            </LeadersBoxRight>
          </LeadersBox>

          <ClosingMarks>
            15.6.2021 9:30 – 12:30 Carlton Hotel Tel-Aviv
            <br />
            Eliezer Peri St 10, Tel Aviv.
            <br />
            For registration or questions, please contact&nbsp;
            <Email href="mailto:events@fortresscyber.io">
              events@fortresscyber.io
            </Email>
          </ClosingMarks>
        </ContentWrapper>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}
