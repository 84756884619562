import * as React from 'react'
import LayoutEvent from '../components/layoutEvent'
import { FooterEvent } from '@/components/Footer/FooterEvent'
import { EventHead } from '@/components/Event'

const IndexPage: React.FC = () => {
  return (
    <>
      <LayoutEvent>
        <EventHead />
        <FooterEvent />
      </LayoutEvent>
    </>
  )
}

export default IndexPage
