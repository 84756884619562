import React, { useState } from 'react'

import styled from 'styled-components'
import { useFormik } from 'formik'
import VALIDATION from '../../constants/validation'
import Input from '../../components/ContactForm/Input'
import contactFormApi, { prepareData } from '../../utils/contactFormApi'
import { Button } from '../StyledComponents/Button'
import SuccessSendModal from './SuccessSendModal'

const { CHECKOUT_FORM } = VALIDATION

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 50px 28px 50px;
  max-width: 724px;
  border-radius: 12px;
  margin: auto;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.rwd.mobile.s}) {
    padding-inline: 10px;
  }
`

const Form = styled.form``

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: space-between;
`

const Title = styled.h1`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  margin-bottom: 30px;
`

const Text = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.bigText};

  margin-top: 15px;
`

const StyledButton = styled(Button)`
  padding: 0px;
  width: 300px;
`

type Props = {
  toggle?: () => void
}

const ContactFormEvent: React.FC<Props> = ({ toggle }) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: 'bbb',
      email: '',
      phoneNumber: '',
      company: '',
      numberOfEmployees: '',
      country: '',
      title: '',
      message: 'landing-event',
      interestedIn: '',
    },
    onSubmit: (data) => {
      contactFormApi(prepareData(data))
      toggleSuccessModal()
    },
    validationSchema: CHECKOUT_FORM,
  })

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)

  const toggleSuccessModal = () => {
    setSuccessModalOpen(!isSuccessModalOpen)
    if (toggle && isSuccessModalOpen) {
      toggle()
    }
  }

  return (
    <>
      <Wrapper>
        <Title>Reserve your Spot</Title>
        <Form name="contact" method="post" onSubmit={formik.handleSubmit}>
          <InputsContainer>
            <Input
              type="text"
              label="Full Name"
              inputName="firstName"
              value={formik.values.firstName}
              error={formik.touched.firstName && formik.errors.firstName}
              onChange={formik.handleChange}
              styles={{ marginBottom: '20px' }}
            />
            <Input
              type="email"
              label="Email"
              inputName="email"
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              styles={{ marginBottom: '20px' }}
            />
            <Input
              type="text"
              label="Company"
              inputName="company"
              value={formik.values.company}
              error={formik.touched.company && formik.errors.company}
              onChange={formik.handleChange}
              styles={{ marginBottom: '20px' }}
            />
            <Input
              type="text"
              label="Phone number"
              inputName="phoneNumber"
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              onChange={formik.handleChange}
              styles={{ marginBottom: '20px' }}
            />
            <StyledButton primary type="submit">
              Register now!
            </StyledButton>
          </InputsContainer>
          <Text>
            15.6.2021 9:30 – 12:30
            <br />
            Carlton Hotel Tel-Aviv
          </Text>
          {/* <PolicyAccept>
            * By clicking Contact Us, I agree to the use of my personal
            data in accordance with Fortress Privacy Policy. Fortress will
            not sell, trade, lease, or rent your personal data to third
            parties.
          </PolicyAccept> */}
        </Form>
      </Wrapper>
      <SuccessSendModal
        isOpen={isSuccessModalOpen}
        toggle={toggleSuccessModal}
      />
    </>
  )
}

export default ContactFormEvent
