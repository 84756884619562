import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import './navbar.scss'
import { useWindowSize } from '../../hooks'
import Logo from '../../assets/fortressLogo.svg'

export const NavigationEvent: React.FC = () => {
  const [scrolled, setScrolled] = useState(false)
  const { width } = useWindowSize()
  const isMobile = width < 1225

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 25) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const fortressLogo = (
    <LogoStyle>
      <img src={Logo} alt={'logo'} width={isMobile ? 130 : 173} />
    </LogoStyle>
  )

  return (
    <>
      <Space display={isMobile ? 'none' : ''} />
      <Wrapper scrolled={scrolled} isMobile={isMobile}>
        {fortressLogo}
      </Wrapper>
    </>
  )
}
const Space = styled.div<{ display: string }>`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 30px;
  display: ${({ display }) => display}; ;
`

const Wrapper = styled.div<{ scrolled?: boolean; isMobile: boolean }>`
  display: flex;
  z-index: 100;
  top: 0;
  position: sticky;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding-inline: 8%;
  padding-block: ${({ scrolled, isMobile }) =>
    scrolled || isMobile ? '10px 10px' : '20px 0'};
  transition: padding 0.3s ease;

  ${({ scrolled }) =>
    scrolled &&
    css`
      box-shadow: 0 11px 11px -11px rgb(198, 198, 198);
      transition: all 0.3s ease;
    `}
`

const LogoStyle = styled.div`
  display: flex;
  place-items: center;
`
